var render = function () {
  var _vm$reservationsData, _vm$reservationsData2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('b-img', {
          staticClass: "icon-card",
          attrs: {
            "src": require("@icons/dollar.svg"),
            "width": "50px"
          }
        }), _c('h6', {
          staticClass: "text-airline font-medium-3 m-0 py-50 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.priceDetails.header')) + " ")])], 1), !_vm.isHideFunctionButton && !_vm.hideFeature && _vm.reservationsData.status !== 'CANCEL' ? _c('div', {
          class: _vm.isMobileView ? 'd-flex-center flex-wrap' : '',
          staticStyle: {
            "margin-right": "2.5rem"
          }
        }, [_vm.isShowToggleServiceFee ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window",
            modifiers: {
              "hover": true,
              "window": true
            }
          }],
          staticClass: "p-50 rounded-circle mx-25",
          attrs: {
            "variant": "flat-primary",
            "title": "".concat(_vm.$t("reservation.".concat(_vm.showServiceFee ? 'Hide' : 'Show')), " ").concat(_vm.$t('reservation.priceDetails.titleTable.feeService'))
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              _vm.showServiceFee = !_vm.showServiceFee;
            }
          }
        }, [_vm.showServiceFee ? _c('img', {
          attrs: {
            "src": require("@icons/money.svg")
          }
        }) : _c('img', {
          attrs: {
            "src": require("@icons/not_money.svg")
          }
        })]) : _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window",
            modifiers: {
              "hover": true,
              "window": true
            }
          }],
          staticClass: "p-50 rounded-circle mx-25",
          attrs: {
            "variant": "flat-primary",
            "title": "".concat(_vm.$t("reservation.".concat(_vm.showServiceFee ? 'Hide' : 'Show')), " ").concat(_vm.$t('reservation.priceDetails.titleTable.agencyFee'))
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              _vm.showAgencyfee = !_vm.showAgencyfee;
            }
          }
        }, [_vm.showAgencyfee ? _c('img', {
          attrs: {
            "src": require("@icons/money.svg")
          }
        }) : _c('img', {
          attrs: {
            "src": require("@icons/not_money.svg")
          }
        })])], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.reservationsData.status === 'CANCEL' ? _c('BAlert', {
    staticClass: "px-2 py-1",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingCancelled')) + " ")]) : _c('b-card-body', {
    staticClass: "d-flex flex-column gap-3 py-25 px-0"
  }, [_vm.reservationsData.paxFareInfoTicket && _vm.reservationsData.paxFareInfoTicket.length ? _c('div', {
    staticClass: "d-flex flex-column gap-2"
  }, _vm._l(_vm.reservationsData.paxFareInfoTicket, function (paxFareInfoTicketItem, paxFareInfoTicketIndex) {
    return _c('b-card', {
      key: paxFareInfoTicketIndex,
      staticClass: "border-secondary",
      attrs: {
        "header-bg-variant": "transparent",
        "header-class": "mb-0 p-50 justify-content-center cursor-none",
        "body-class": "pb-0 px-0"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "fw-600 mb-0 d-flex overflow-auto gap-1 text-warning font-medium-3 "
          }, [['VN1A', 'VN1A_MT', '1A'].includes(_vm.reservationsData.source) ? [_c('div', {
            staticClass: "d-flex"
          }, _vm._l(paxFareInfoTicketItem === null || paxFareInfoTicketItem === void 0 ? void 0 : paxFareInfoTicketItem.trips, function (segment, segmentIndex) {
            return _c('div', {
              key: segmentIndex,
              class: "pr-50 mr-50 ".concat(segmentIndex !== (paxFareInfoTicketItem === null || paxFareInfoTicketItem === void 0 ? void 0 : paxFareInfoTicketItem.trips.length) - 1 ? 'border-right-secondary' : '')
            }, [_c('div', {
              staticClass: "text-center fw-800 text-nowrap"
            }, [_vm._v(" " + _vm._s(segment.departure) + " - " + _vm._s(segment.arrival) + " ")]), ['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('span', {
              class: "fw-700 text-nowrap ".concat(_vm.isPaidSegment(_vm.reservationsData.itineraries, segment) ? 'text-success' : 'text-warning')
            }, [_vm._v(" " + _vm._s(_vm.isPaidSegment(_vm.reservationsData.itineraries, segment) ? _vm.$t('reservation.PAID') : _vm.$t('reservation.UNPAID')) + " ")]) : _vm._e()]);
          }), 0)] : [_vm._v(" " + _vm._s(_vm.resolveTrip(paxFareInfoTicketItem === null || paxFareInfoTicketItem === void 0 ? void 0 : paxFareInfoTicketItem.trips)) + " ")]], 2)];
        },
        proxy: true
      }], null, true)
    }, [_c('b-table', {
      staticClass: "mb-0 border",
      attrs: {
        "centered": "",
        "responsive": "",
        "small": "",
        "thead-class": "text-dark align-middle text-nowrap text-right",
        "tbody-class": "text-dark align-middle text-nowrap",
        "fields": _vm.tableColumnsPaxFareInfoTicket,
        "items": _vm.resolvePaxFareInfoTicket(paxFareInfoTicketItem)
      },
      scopedSlots: _vm._u([_vm._l(_vm.tableColumnsPaxFareInfoTicket, function (column, indexColumn) {
        return {
          key: "head(".concat(column, ")"),
          fn: function fn(data) {
            return [_c('span', {
              key: indexColumn
            }, [_vm._v(" " + _vm._s(_vm.$t("reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.".concat(data.column))) + " ")])];
          }
        };
      }), {
        key: "cell(passenger)",
        fn: function fn(data) {
          var _data$item;
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('span', [_vm._v(_vm._s(_vm.$te("reservation.".concat(data.item.paxType)) ? _vm.$t("reservation.".concat(data.item.paxType)) : data.item.paxType) + _vm._s((_data$item = data.item) !== null && _data$item !== void 0 && _data$item.amountPassenger ? ' x' + data.item.amountPassenger : ''))])])];
        }
      }, {
        key: "cell(netPrice)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(data.item.netPrice)))])])];
        }
      }, {
        key: "cell(tax)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(_vm.showServiceFee ? data.item.tax - data.item.feeService : _vm.showAgencyfee ? data.item.tax - data.item.fee : data.item.tax)))])])];
        }
      }, {
        key: "cell(total)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(data.item.total)))])])];
        }
      }, {
        key: "cell(feeService)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(data.item.feeService)))])])];
        }
      }, {
        key: "cell(agencyFee)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(data.item.fee)))])])];
        }
      }], null, true)
    }), _c('div', {
      staticClass: "font-weight-bolder text-danger text-right my-50 d-flex-center w-100 justify-content-end gap-2"
    }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('reservation.total')) + ":")]), _c('span', {
      staticClass: "ml-50 text-20px"
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.sumTotalArrayTicket[paxFareInfoTicketIndex])) + " ")])]), _c('div', {
      staticClass: "px-1"
    }, [_c('feather-icon', {
      staticClass: "text-warning mr-25",
      attrs: {
        "icon": "InfoIcon"
      }
    }), _c('span', {
      staticClass: "text-nowrap text-italic text-warning font-small-3"
    }, [_vm._v(_vm._s(_vm.$t('flight.fareText')))])], 1)])], 1);
  }), 1) : _vm.reservationsData.priceQuotes && _vm.reservationsData.priceQuotes.length || _vm.reservationsData.historicalPriceQuotes && _vm.reservationsData.historicalPriceQuotes.length ? _c('div', [_vm.reservationsData.priceQuotes && !_vm.reservationsData.priceQuotes.length && _vm.reservationsData.historicalPriceQuotes && _vm.reservationsData.historicalPriceQuotes.length > 0 ? _c('div', [_c('BCard', {
    staticClass: "border-secondary mb-50",
    attrs: {
      "header-bg-variant": "transparent",
      "header-class": "mb-0 p-50 justify-content-center cursor-none",
      "body-class": "pb-0 px-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "fw-600 mb-0 d-flex overflow-auto gap-1 text-warning font-medium-3 "
        }, [_vm._v(" " + _vm._s(_vm.getPricesByPriceQuotesTicket(_vm.reservationsData.historicalPriceQuotes).items.map(function (i) {
          return i.flight;
        }).join(', ')) + " ")])];
      },
      proxy: true
    }], null, false, 1481692928)
  }, [_c('b-table-lite', {
    attrs: {
      "responsive": "",
      "centered": "",
      "thead-class": "text-dark align-middle text-nowrap text-right",
      "tbody-class": "text-dark align-middle text-nowrap text-right border",
      "fields": _vm.tableColumnsPriceQuotes,
      "items": _vm.getPricesByPriceQuotesTicket(_vm.reservationsData.historicalPriceQuotes).items
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsPriceQuotes, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.priceDetails.titleTable.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(paxType)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.$t("reservation.".concat(item.paxType))) + " x" + _vm._s(item.amount))])];
      }
    }, {
      key: "cell(flight)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(item.flight))])];
      }
    }, {
      key: "cell(fare)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.fare)))])])];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.tax - (_vm.showServiceFee ? item.feeService : _vm.showAgencyfee ? item.fee : 0))))])])];
      }
    }, {
      key: "cell(feeService)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.feeService)))])])];
      }
    }, {
      key: "cell(agencyFee)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.fee)))])])];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.total)))])])];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "font-weight-bolder text-danger text-right my-50 d-flex-center w-100 justify-content-end gap-2"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('reservation.total')) + ":")]), _c('span', {
    staticClass: "ml-50 text-20px"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getPricesByPriceQuotesTicket(_vm.reservationsData.historicalPriceQuotes).total)) + " ")])]), _c('div', {
    staticClass: "px-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning mr-25",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _c('span', {
    staticClass: "text-nowrap text-italic text-warning font-small-3"
  }, [_vm._v(_vm._s(_vm.$t('flight.fareText')))])], 1)])], 1)], 1) : _vm.reservationsData.priceQuotes && _vm.reservationsData.priceQuotes.length > 0 ? _c('div', [_c('BCard', {
    staticClass: "border-secondary mb-50",
    attrs: {
      "header-bg-variant": "transparent",
      "header-class": "mb-0 p-50 justify-content-center cursor-none",
      "body-class": "pb-0 px-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "fw-600 mb-0 d-flex overflow-auto gap-1 text-warning font-medium-3 "
        }, [_vm._v(" " + _vm._s(_vm.getPricesByPriceQuotesTicket(_vm.reservationsData.priceQuotes).items.map(function (i) {
          return i.flight;
        }).join(', ')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-table-lite', {
    attrs: {
      "responsive": "",
      "centered": "",
      "thead-class": "text-dark align-middle text-nowrap text-right",
      "tbody-class": "text-dark align-middle text-nowrap text-right border",
      "fields": _vm.tableColumnsPriceQuotes,
      "items": _vm.getPricesByPriceQuotesTicket(_vm.reservationsData.priceQuotes).items
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsPriceQuotes, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.priceDetails.titleTable.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(paxType)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.$t("reservation.".concat(item.paxType))) + " x" + _vm._s(item.amount))])];
      }
    }, {
      key: "cell(flight)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(item.flight))])];
      }
    }, {
      key: "cell(fare)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.fare)))])])];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.tax - (_vm.showServiceFee ? item.feeService : _vm.showAgencyfee ? item.fee : 0))))])])];
      }
    }, {
      key: "cell(feeService)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.feeService)))])])];
      }
    }, {
      key: "cell(agencyFee)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.fee)))])])];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.total)))])])];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "font-weight-bolder text-danger text-right my-50 d-flex-center w-100 justify-content-end gap-2"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('reservation.total')) + ":")]), _c('span', {
    staticClass: "ml-50 text-20px"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getPricesByPriceQuotesTicket(_vm.reservationsData.priceQuotes).total)) + " ")])]), _c('div', {
    staticClass: "px-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning mr-25",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _c('span', {
    staticClass: "text-nowrap text-italic text-warning font-small-3"
  }, [_vm._v(_vm._s(_vm.$t('flight.fareText')))])], 1)])], 1)], 1) : _vm._e()]) : _c('div', [_c('BCard', {
    staticClass: "border-secondary mb-50",
    attrs: {
      "header-bg-variant": "transparent",
      "header-class": "mb-0 p-50 justify-content-center cursor-none",
      "body-class": "p-0 rounded-8px"
    }
  }, [_c('b-table-lite', {
    staticClass: "rounded-8px",
    attrs: {
      "bordered": "",
      "responsive": "",
      "centered": "",
      "tbody-class": "text-dark align-middle",
      "fields": _vm.tableColumnsFareInfoTicket,
      "items": [_vm.reservationsData.fareInfoTicket]
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsFareInfoTicket, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.priceDetails.titleTable.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(netFare)",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.netFare)))])])];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(_vm.showServiceFee ? item.tax - _vm.reservationsData.totalFeeService : item.tax)))])])];
      }
    }, {
      key: "cell(feeService)",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(_vm.reservationsData.totalFeeService)))])])];
      },
      proxy: true
    }, {
      key: "cell(total)",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.total)))])])];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "font-weight-bolder text-danger text-right my-50 d-flex-center w-100 justify-content-end gap-2"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('reservation.total')) + ":")]), _c('span', {
    staticClass: "ml-50 text-20px"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.reservationsData.fareInfoTicket.total)) + " ")])]), _c('div', {
    staticClass: "px-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning mr-25",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _c('span', {
    staticClass: "text-nowrap text-italic text-warning font-small-3"
  }, [_vm._v(_vm._s(_vm.$t('flight.fareText')))])], 1)])], 1)], 1), _c('BCard', {
    staticClass: "border-secondary",
    attrs: {
      "header-bg-variant": "transparent",
      "header-class": "mb-0 py-50 px-1 justify-content-start cursor-none",
      "body-class": "pb-0 px-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "fw-600 mb-0 d-flex overflow-auto gap-1 text-body font-medium-3"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.services')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('AncillaryServicesComponent', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "is-booking-out-system": _vm.isBookingOutSystem,
      "is-hide-function-button": _vm.isHideFunctionButton,
      "can-issue-limit": _vm.canIssueLimit,
      "is-locked-modify": _vm.isLockedModify,
      "total-ancillary-service-price": _vm.totalAncillaryServicePrice
    },
    on: {
      "update:totalAncillaryServicePrice": function updateTotalAncillaryServicePrice($event) {
        _vm.totalAncillaryServicePrice = $event;
      },
      "update:total-ancillary-service-price": function updateTotalAncillaryServicePrice($event) {
        _vm.totalAncillaryServicePrice = $event;
      }
    }
  }), _vm.reservationsData.source.includes('1S') && _vm.reservationsData.status === 'HOLD' && !_vm.isEmpty(_vm.reservationsData.ancillaryServices.filter(function (item) {
    return item.status && ['HD'].includes(item.status);
  })) ? _c('b-alert', {
    staticClass: "py-75 px-1 mx-lg-3 mx-sm-1 text-center fw-700",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Vietnam Airlines: Vui lòng thanh toán hành trình trước khi thanh toán dịch vụ")])]) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex-between"
  }, [_c('div', {
    staticClass: "px-1 py-50 rounded d-flex-center align-items-start flex-column",
    style: "border: 1px solid ".concat(_vm.showBenefit ? '#E6E6E6' : 'transparent', "; height: 72px")
  }, [_c('div', [!_vm.hideFeature ? _c('BButton', {
    staticClass: "p-25 rounded-circle",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.showBenefit = !_vm.showBenefit;
      }
    }
  }, [_vm.showBenefit ? _c('feather-icon', {
    attrs: {
      "icon": "EyeIcon",
      "size": "16"
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "EyeOffIcon",
      "size": "16"
    }
  })], 1) : _vm._e()], 1), _vm.showBenefit ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('reservation.benefit')) + ": ")]), _c('span', {
    class: "text-".concat(_vm.benefit > 0 ? 'success' : 'danger', " ml-50 font-medium-4")
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.benefit)) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "font-weight-bolder text-right"
  }, [_c('div', [_c('span', {
    staticClass: "text-body font-medium-3"
  }, [_vm._v(_vm._s(_vm.$t('reservation.totalPirce')) + ":")]), _c('span', {
    staticClass: "text-danger ml-50 font-large-1"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalAllTicket)) + " ")])]), _vm.showServiceFee || _vm.showAgencyfee ? _c('div', [_c('span', {
    staticClass: "text-body font-medium-1"
  }, [_vm._v(_vm._s(_vm.$t('reservation.pricePaidToSupplier')) + ":")]), _c('span', {
    staticClass: "text-success ml-50 font-medium-4"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalAll)) + " ")])]) : _vm._e(), _vm.reservationsData.source === 'VJ' && (_vm$reservationsData = _vm.reservationsData) !== null && _vm$reservationsData !== void 0 && _vm$reservationsData.refundAmountIdentification ? _c('div', {
    staticClass: "d-flex-center justify-content-end"
  }, [_c('div', {
    staticClass: "text-nowrap text-warning font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.amountRefunded')) + ": ")]), _c('div', {
    staticClass: "text-right font-weight-bolder font-medium-4 text-warning ml-50"
  }, [_vm._v(" -" + _vm._s(_vm.formatCurrency((_vm$reservationsData2 = _vm.reservationsData) === null || _vm$reservationsData2 === void 0 ? void 0 : _vm$reservationsData2.refundAmountIdentification)) + " ")])]) : _vm._e()])])], 1), _c('ModalSetServiceFee', {
    attrs: {
      "reservations-data": _vm.reservationsData
    }
  })], 1)], 1), !_vm.hideFeature && !_vm.isHideFunctionButton ? _c('div', {
    staticClass: "d-flex-center justify-content-end my-50 gap-1 flex-wrap"
  }, [_vm.isVisibleCustomServiceFee ? _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-set-service-fee",
      modifiers: {
        "modal-set-service-fee": true
      }
    }],
    attrs: {
      "variant": "outline-warning",
      "disasbled": _vm.isBookingOutSystem || !_vm.canIssueLimit || _vm.reservationsData.status === 'CANCEL',
      "size": "sm"
    }
  }, [_c('feather-icon', {
    staticClass: "d-inline mr-50",
    attrs: {
      "icon": "ToolIcon"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.sellingPriceDetails.reSetServiceFee')))])], 1) : _vm._e(), ['1S', '1S_CTRL'].includes(_vm.bookingSource) ? _c('b-button', {
    staticClass: "d-flex-center text-nowrap px-75",
    attrs: {
      "variant": "success",
      "disabled": _vm.disabledPaymentAncillary(_vm.reservationsData) || !_vm.canAccess('booking.issueTicket') || _vm.isBookingOutSystem || !_vm.canIssueLimit || _vm.isLockedModify || _vm.reservationsData.status === 'CANCEL',
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.openModalPaymentAncillaryServices();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "DollarSignIcon"
    }
  }), _c('span', {
    staticClass: "font-small-4",
    staticStyle: {
      "padding-bottom": "1px"
    }
  }, [_vm._v(_vm._s(_vm.$t('reservation.paymentAncillary')))])], 1) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }